import { Link } from 'react-router-dom'
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faCirclePlus, faFileExport, faFileImport, faRectangleXmark } from '@fortawesome/free-solid-svg-icons';
import '../App.css';



function getURL() {

  const storedElements = localStorage.getItem('ListaElementos');
  let data = "";
  let dataExists = false;

  if (storedElements) {
      data = storedElements;
      dataExists = true;
  }
  
  if (dataExists) { 
    let url = window.location.origin + "?export=true&data=" + data;
    const pResponse = document.getElementById('responseExport');
    pResponse.innerHTML = "Copia y pega la siguiente dirección en el navegador que quieras importar tus listas: <br /><br />" + url;
  } else {
    const pResponse = document.getElementById('responseExport');
    pResponse.innerHTML = "No tienes ninguna lista que exportar :-(";
  }

}

// Función para comprobar que no se quiera importar
function importListfromAnotherDevice() {

  const queryString = window.location.search;

  if (queryString) {
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.get('export') === "true") {
      console.log("Importing list from another device");
      const dataLists = JSON.parse(urlParams.get('data'));
      
      if (typeof dataLists === "object") {
        return true;
      } else {
        return false;
      }
    } else {
        return false;
    }

  }
}

function Home() {

  let isImporting = importListfromAnotherDevice();

  
  const handleConfirm = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const dataListados = urlParams.get('data');
    localStorage.setItem("ListaElementos", dataListados);

    const changeContentImporter = document.getElementById('importer');
    changeContentImporter.classList.add('nodisplay');
    changeContentImporter.classList.remove('dispblock');

    const changeContentImported = document.getElementById('imported');
    changeContentImported.classList.add('dispblock');
    changeContentImported.classList.remove('nodisplay');

    isImporting = false;

  };

  const handleCancel = () => {
    console.log('Cancelo');
    
  };

  useEffect(() => {
    isImporting = false;
  }, handleCancel);

  if (isImporting) {
    return (
      <div className="maincontainer bg-slate-700 min-h-screen">
          <div className='max-w-5xl mx-auto'>
            
            <h1 className="text-3xl font-bold text-white m-20 mb-10 inline-block ">TodoList</h1>

            <div id="importer" className="w-full text-white bold mt-5 mb-5 p-5 dispblock">
              
              <h1 className="text-2xl font-bold text-white m-5 mb-10 inline-block">¿Seguro que quieres importar el contenido?<br />Esto eliminará tus listas actuales...</h1>

              <div className='flex flex-grow justify-around w-6/12 m-auto'>

                <a className="bg-slate-900 hover:bg-slate-800 text-1xl font-bold text-white m-1 p-5 border border-white rounded-lg w-1/2 cursor-pointer" onClick={handleConfirm}><FontAwesomeIcon icon={faFileImport} /> Importar</a>

                <a className="bg-slate-900 hover:bg-slate-800 text-1xl font-bold text-white m-1 p-5 border border-white rounded-lg w-1/2 cursor-pointer" onClick={handleCancel}><FontAwesomeIcon icon={faRectangleXmark} /> Cancelar</a>
              </div>
            </div>

            <div id="imported" className="w-6/12 text-white bold mt-5 mb-5 p-5 m-auto nodisplay">
                <h1 className="text-2xl font-bold text-white m-5 mb-10 inline-block">Contenido importado correctamente.</h1>
                <Link className='block w-full bg-slate-900 hover:bg-slate-800 text-white p-6 mb-3 border rounded-md text-1xl font-bold' to="/listas"><FontAwesomeIcon icon={faList} /> Ver mis listas</Link>
            </div>
          

        </div>
      </div>
    )
  } else {
    return (
      <div className="maincontainer bg-slate-700 h-screen">
          <div className='max-w-5xl mx-auto'>

            <h1 className="text-3xl font-bold text-white m-20 mb-10 inline-block ">TodoList</h1>
            <Link className='block w-full bg-slate-900 hover:bg-slate-800 text-white p-6 mb-3 border rounded-md text-1xl font-bold' to="/listas"><FontAwesomeIcon icon={faList} /> Ver mis listas</Link>

            <Link className='block w-full bg-slate-900 hover:bg-slate-800 text-white p-6 mb-3 border rounded-md text-1xl font-bold' to="/create">
              <FontAwesomeIcon icon={faCirclePlus} /> Crear nueva lista</Link>

            <a className='block w-full bg-slate-900 hover:bg-slate-800 text-white p-6 mb-3 border rounded-md text-1xl font-bold cursor-pointer'onClick={getURL}><FontAwesomeIcon icon={faFileExport} /> Exportar listas a otro dispositivo</a>

            <p className='text-white font-bold mt-6' id="responseExport"></p>

        </div>
      </div>
    )
  }


    

}

export default Home;
