import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import './App.css';
import List from './components/List';
import CreateList from './components/CreateList';
import ManageListas from './components/ManageListas';
import Home from './components/Home';

function App() {
  const id = 1;
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/listas" element={<ManageListas />} />
        <Route path="/create" element={<CreateList />} />
        <Route path="/lista" element={<List id={id} />} />
      </Routes>
    </div>
  )
  
}

function Router() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
}

export default Router;