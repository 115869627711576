import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faTrash, faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import '../App.css';
import { useState } from 'react';

function ManageListas() {
  const [Elements, setElements] = useState(() => {
    const storedElements = localStorage.getItem('ListaElementos');
    return storedElements ? JSON.parse(storedElements) : [];
  });

  let listItems = [];

  const handleClick = (value) => {
    const arrayModified = [...Elements];
    arrayModified.splice(value, 1);
    localStorage.setItem("ListaElementos", JSON.stringify(arrayModified));
    setElements(arrayModified);
  }

  if (Elements.length > 0) {
    listItems = Elements.map((item, index) => {
      return <li 
                className='listElement bg-item-800 hover:bg-slate-600 p-5 border-2 border-slate-500 max-w-5xl mb-3 rounded-lg m-auto text-white font-semibold relative' 
                name={item.name}
                key={item}>
                    <Link to={`/lista?id=${index}`}>{ item[0] }</Link>
                    <FontAwesomeIcon icon={faTrash} onClick={() => handleClick(index)} />
              </li>
    });

    } else {

    }

    if (Elements.length > 0) {
      return (
        <div className="App bg-slate-700  min-h-screen maincontainer">
            <h1 className="text-3xl font-bold text-white m-20 mb-10 inline-block">Listas creadas</h1>
            <ul className="list-none text-left">{listItems}</ul>

            <div className='max-w-5xl mx-auto block mt-6'>
              <div className='flex flex-grow justify-around'>
                <Link to='/create' className=' bg-slate-900 hover:bg-slate-800 text-1xl font-bold text-white m-1 p-5 border border-white rounded-lg w-1/2'><FontAwesomeIcon icon={faCirclePlus} /> Crear lista</Link>
                <Link to='/' className=' bg-slate-900 hover:bg-slate-800 text-1xl font-bold text-white m-1 p-5 border border-white rounded-lg w-1/2'><FontAwesomeIcon icon={faCircleArrowLeft} /> Inicio</Link>
              </div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="App bg-slate-700 h-screen">
          <div className='max-w-5xl mx-auto'>
          <h1 className="text-3xl font-bold text-white m-20 mb-10 inline-block">No tienes Listas creadas</h1>
            <Link className='block w-full bg-slate-900 hover:bg-slate-800 text-white p-6 mb-6 border rounded-md' to="/create">
                <FontAwesomeIcon icon={faCirclePlus} /> Crear nueva lista
            </Link>
          </div>

        </div>
      )
    }

  
}

export default ManageListas;
