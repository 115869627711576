import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import '../App.css';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom'

function CreateList() {
      
    let arrayList = localStorage.getItem('ListaElementos');
    if (arrayList) {
        arrayList = JSON.parse(arrayList);
    } else {
        arrayList = [];
    }

    const navigate = useNavigate();
    const [volver, setvolver] = useState(false);
 
    const [newValue, setNewValue] = useState('');
    
    const handleSubmit = (event) => {
        event.preventDefault();
      
        if (newValue !== "") {
            arrayList.push([]);
            arrayList[arrayList.length - 1][0] = newValue;
            localStorage.setItem("ListaElementos", JSON.stringify(arrayList));
            setNewValue(""); 
            setvolver(true);
        }
    
    };
    
      const handleChange = (event) => {
        setNewValue(event.target.value);
      }

      useEffect(() => {
        if (volver) {
          navigate('/listas');
        }
      }, [volver, navigate]);
    
      return (
    
        <div className="maincontainer bg-slate-700 h-screen">
    
          <h1 className="text-3xl font-bold text-white m-20 mb-10 inline-block">
            Crear nueva lista
          </h1>
    
          <form onClick={handleSubmit} className='max-w-5xl mb-3 rounded-md m-auto'>
            <input type="text" 
              name="addElement" 
              onChange={handleChange} 
              value={newValue}
              placeholder='Nombre de la lista...'
              className='w-full	p-[10px] h-12 text-indigo-950 rounded-lg font-semibold border-0 mb-6'
            ></input>
            
            <div className='max-w-5xl mx-auto block mt-6'>
              <div className='flex flex-grow justify-around'>
                <button className="bg-slate-900 hover:bg-slate-800 text-1xl font-bold text-white m-1 p-5 border border-white rounded-lg w-1/2"><FontAwesomeIcon icon={faCirclePlus} /> Añadir</button>
                <Link to='/' className=' bg-slate-900 hover:bg-slate-800 text-1xl font-bold text-white m-1 p-5 border border-white rounded-lg w-1/2'><FontAwesomeIcon icon={faCircleArrowLeft} /> Volver</Link>
              </div>
            </div>

          </form>
    
        </div>
      );
}

export default CreateList;