import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCirclePlus, faTrash, faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom'
import '../App.css';


function List() {

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const idActual = urlParams.get('id')


    //const idActual = parseInt(value.id);
    let arrayOriginal = JSON.parse(localStorage.getItem('ListaElementos'));

    const [Elements, setElements] = useState(() => {
        const storedElements = JSON.parse(localStorage.getItem('ListaElementos'));

        if (storedElements) {
            return storedElements[idActual];
        } else {
            return [];
        }
      });

      
      const [newValue, setNewValue] = useState('');
    
    const handleClick = (value) => {
        const updatedElements = Elements.filter((item) => item !== value);
        setElements(updatedElements);
        if (arrayOriginal) {
            arrayOriginal[idActual] = updatedElements;
        }
        localStorage.setItem("ListaElementos", JSON.stringify(arrayOriginal));
        setNewValue(""); 
    }
    
      const handleSubmit = (event) => {
        event.preventDefault();
      
        if (newValue !== "") {
          const updatedElements = [...Elements, newValue];
          setElements(updatedElements);
          if (arrayOriginal) {
            arrayOriginal[idActual] = updatedElements;
          }
          localStorage.setItem("ListaElementos", JSON.stringify(arrayOriginal));
          setNewValue(""); 
        }
    
      };
    
      const handleChange = (event) => {
        setNewValue(event.target.value);
      }

      let listItems = [];
    
      if (Elements.length > 1) {
        listItems = Elements.slice(1).map((item) => {
          return <li 
                    className='listElement bg-item-800 hover:bg-slate-600 p-5 border-2 border-slate-500 max-w-5xl mb-3 rounded-lg m-auto text-white font-semibold relative' 
                    name={item.name}
                    key={item}>
                      { item } <FontAwesomeIcon icon={faTrash} onClick={() => handleClick(item)} />
                  </li>
        });
      }
    
      return (
    
        <div className="App bg-slate-700 maincontainer min-h-screen">
    
          <h1 className="text-3xl font-bold text-white m-5 mb-10 inline-block">
            Añadir elementos a la lista <span className="text-yellow-500 text-4xl">{ Elements[0] }</span>
          </h1>
   
          <form onClick={handleSubmit} className='max-w-5xl mb-3 rounded-md m-auto'>
            <input type="text" 
              name="addElement" 
              onChange={handleChange} 
              value={newValue}
              placeholder='Añadir elemento a la lista...'
              className='w-full	p-[10px] h-12 text-indigo-950 rounded-lg font-semibold border-0 mb-6'
            ></input>
            
            <div className='max-w-5xl mx-auto block mt-6'>
              <div className='flex flex-grow justify-around'>
                <button className="bg-slate-900 hover:bg-slate-800 text-1xl font-bold text-white m-1 p-5 border border-white rounded-lg w-1/2"><FontAwesomeIcon icon={faCirclePlus} /> Añadir</button>
                <Link to='/' className=' bg-slate-900 hover:bg-slate-800 text-1xl font-bold text-white m-1 p-5 border border-white rounded-lg w-1/2'><FontAwesomeIcon icon={faCircleArrowLeft} /> Volver</Link>
              </div>
            </div>

            
          </form>
          
          {Elements.length > 0 ? (
            <ul className="list-none text-left">{listItems}</ul>
          ) : (
          <p className='text-white text-2xl'>La lista está vacío por ahora.</p>
          )}
          
    
        </div>
      );
}

export default List;